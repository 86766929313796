import React from "react"
import Layout from "components/Layout"
import { useForm } from "react-hook-form"

const Build = () => {
  const [loading, setLoading] = React.useState(false)
  const [auth, setAuth] = React.useState(false)
  const [building, setBuilding] = React.useState(false)
  const { register, handleSubmit } = useForm()
  const onSubmit = data => {
    if (data.password !== "StripeMandatory#2022") return
    setAuth(true)
  }
  const handleConfirmBuild = async () => {
    try {
      setBuilding(true)
      setLoading(true)
      const request = await fetch(
        `https://api.netlify.com/build_hooks/6348aa8cd595d93209008762`,
        {
          method: "POST",
        }
      )
    } catch (e) {
      alert(`something didn't work. please contact Claudio`)
      console.log(e)
      setLoading(false)
      setBuilding(false)
      setAuth(false)
    }
  }
  return (
    <div>
      <Layout>
        <div className="flex flex-col items-center container mx-auto max-w-xl pt-10">
          {!auth && (
            <form
              className="flex flex-col items-center"
              onSubmit={handleSubmit(onSubmit)}
            >
              <input
                {...register("password")}
                className="rounded-md border w-[300px] border-gray-300 m-4 mt-4 p-4 text-base text-gray-600 focus:outline-none focus:border-gray-700"
                type="password"
                aria-label="password"
                placeholder="Password"
                disabled={loading}
                required
              />
              <button
                type="submit"
                disabled={loading}
                className="bg-lime-700 hover:bg-lime-600 w-[300px] rounded-md md:mt-5 leading-4 p-4 text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-700 disabled:opacity-50"
              >
                Build
              </button>
            </form>
          )}
          {auth && (
            <div className="flex flex-col items-center px-5">
              {!building && (
                <React.Fragment>
                  <p>
                    This will re-build your mandatory insect website. bringin
                    all data and products from STRIPE
                  </p>
                  <p>
                    This Process will take about 2-5 minutes. After that you can
                    refresh your website to see your changes.
                  </p>

                  <p>Please use this method no more than 2 times a day.</p>
                  <p>
                    If you don't see changes after 5 minutes please contact
                    Claudio at: hello@claudiomoscoso.com
                  </p>
                </React.Fragment>
              )}
              {building && (
                <React.Fragment>
                  <p>
                    The robots are building the website! this will take about 2
                    - 5 min.
                  </p>
                  <p>you can close this page now.</p>
                </React.Fragment>
              )}
              <button
                onClick={handleConfirmBuild}
                type="button"
                disabled={loading}
                className="bg-lime-700 hover:bg-lime-600 w-[300px] rounded-md md:mt-5 leading-4 p-4 text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-700 disabled:opacity-50"
              >
                {building ? "Building your website..." : "Confirm Build"}
              </button>
            </div>
          )}
        </div>
      </Layout>
    </div>
  )
}

export default Build
